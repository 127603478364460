import { Component, OnInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  scrollTop() {
    ($ => {
      $('html, body').animate(
        {
          scrollTop: 0
        },
        600
      );
    })(jQuery);
    console.log('scrollTop');
  }
}
