import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([{ path: 'about', loadChildren: 'app/about/about.module#AboutModule' }]),
  Shell.childRoutes([{ path: 'service', loadChildren: 'app/service/service.module#ServiceModule' }]),
  Shell.childRoutes([{ path: 'contact', loadChildren: 'app/contact/contact.module#ContactModule' }]),
  Shell.childRoutes([{ path: 'gallary', loadChildren: 'app/gallery/gallery.module#GalleryModule' }]),
  Shell.childRoutes([{ path: 'quality', loadChildren: 'app/quality/quality.module#QualityModule' }]),
  Shell.childRoutes([{ path: 'award', loadChildren: 'app/award/award.module#AwardModule' }]),
  Shell.childRoutes([{ path: 'clients', loadChildren: 'app/recentclient/recentclient.module#RecentclientModule' }]),
  Shell.childRoutes([{ path: 'manpower', loadChildren: 'app/manpower/manpower.module#ManpowerModule' }]),
  Shell.childRoutes([{ path: 'carrer', loadChildren: 'app/carrer/carrer.module#CarrerModule' }]),
  Shell.childRoutes([{ path: 'epc', loadChildren: 'app/epc/epc.module#EpcModule' }]),
  Shell.childRoutes([{ path: 'cross', loadChildren: 'app/cross/cross.module#CrossModule' }]),
  Shell.childRoutes([{ path: 'cathodic', loadChildren: 'app/cathodic/cathodic.module#CathodicModule' }]),
  Shell.childRoutes([{ path: 'city', loadChildren: 'app/city/city.module#CityModule' }]),
  Shell.childRoutes([{ path: 'horiontal', loadChildren: 'app/horiontal/horiontal.module#HoriontalModule' }]),
  Shell.childRoutes([{ path: 'pipeline', loadChildren: 'app/pipeline/pipeline.module#PipelineModule' }]),
  Shell.childRoutes([{ path: 'support', loadChildren: 'app/support/support.module#SupportModule' }]),
  Shell.childRoutes([{ path: 'inprogress', loadChildren: 'app/inprogress/inprogress.module#InprogressModule' }]),
  Shell.childRoutes([{ path: 'hsse', loadChildren: 'app/hsse/hsse.module#HsseModule' }]),
  Shell.childRoutes([{ path: 'essar', loadChildren: 'app/essar/essar.module#EssarModule' }]),
  Shell.childRoutes([{ path: 'ggas', loadChildren: 'app/ggas/ggas.module#GgasModule' }]),
  Shell.childRoutes([{ path: 'adani', loadChildren: 'app/adani/adani.module#AdaniModule' }]),
  Shell.childRoutes([{ path: 'gspl', loadChildren: 'app/gspl/gspl.module#GsplModule' }]),
  Shell.childRoutes([{ path: 'ioag', loadChildren: 'app/ioag/ioag.module#IoagModule' }]),
  Shell.childRoutes([{ path: 'irm', loadChildren: 'app/irm/irm.module#IrmModule' }]),
  Shell.childRoutes([{ path: 'sabarmati', loadChildren: 'app/sabarmati/sabarmati.module#SabarmatiModule' }]),
  Shell.childRoutes([
    { path: 'companydetails', loadChildren: 'app/companydetails/companydetails.module#CompanydetailsModule' }
  ]),
  Shell.childRoutes([
    { path: 'projectsdetails', loadChildren: 'app/projectsdetails/projectsdetails.module#ProjectsdetailsModule' }
  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
