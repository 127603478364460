import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { QuoteService } from './quote.service';
import { Router } from '@angular/router';
declare const jQuery: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  quote: string;
  isLoading: boolean;

  constructor(private quoteService: QuoteService, private router: Router) {}

  ngOnInit() {
    this.isLoading = true;
    // this.quoteService
    //   .getRandomQuote({ category: 'dev' })
    //   .pipe(
    //     finalize(() => {
    //       this.isLoading = false;
    //     })
    //   )
    //   .subscribe((quote: string) => {
    //     this.quote = quote;
    //   });
    this.jqueries();
  }
  essar() {
    console.log('##');

    this.router.navigate(['./essar']);
  }

  adani() {
    this.router.navigate(['./adani']);
  }

  jqueries() {
    ($ => {
      if ($('#rev_slider_46_1').revolution != undefined) {
        const revapi4 = $('#rev_slider_46_1')
          .show()
          .revolution({
            sliderType: 'standard',
            jsFileLocation: 'revolution/js/',
            sliderLayout: 'fullscreen',
            dottedOverlay: 'none',
            delay: 9000,
            navigation: {
              keyboardNavigation: 'off',
              keyboard_direction: 'horizontal',
              mouseScrollNavigation: 'off',
              onHoverStop: 'off',
              touch: {
                touchenabled: 'on',
                swipe_threshold: 75,
                swipe_min_touches: 1,
                swipe_direction: 'horizontal',
                drag_block_vertical: false
              },
              arrows: {
                style: 'zeus',
                enable: true,
                hide_onmobile: true,
                hide_under: 600,
                hide_onleave: true,
                hide_delay: 200,
                hide_delay_mobile: 1200,
                tmp: '<div class="tp-title-wrap">  	<div class="tp-arr-imgholder"></div> </div>',
                left: {
                  h_align: 'left',
                  v_align: 'center',
                  h_offset: 30,
                  v_offset: 0
                },
                right: {
                  h_align: 'right',
                  v_align: 'center',
                  h_offset: 30,
                  v_offset: 0
                }
              },
              bullets: {
                enable: true,
                hide_onmobile: true,
                hide_under: 600,
                style: 'metis',
                hide_onleave: true,
                hide_delay: 200,
                hide_delay_mobile: 1200,
                direction: 'horizontal',
                h_align: 'center',
                v_align: 'bottom',
                h_offset: 0,
                v_offset: 30,
                space: 5
              }
            },
            viewPort: {
              enable: true,
              outof: 'pause',
              visible_area: '80%'
            },
            responsiveLevels: [1240, 1024, 778, 480],
            gridwidth: [1240, 1024, 778, 480],
            gridheight: [600, 600, 500, 400],
            lazyType: 'none',
            parallax: {
              type: 'mouse',
              origo: 'slidercenter',
              speed: 2000,
              levels: [2, 3, 4, 5, 6, 7, 12, 16, 10, 50]
            },
            shadow: 0,
            spinner: 'off',
            stopLoop: 'off',
            stopAfterLoops: -1,
            stopAtSlide: -1,
            fullScreenAlignForce: 'off',
            fullScreenOffsetContainer: '',
            fullScreenOffset: '0px',
            disableProgressBar: 'on',
            hideThumbsOnMobile: 'off',
            shuffle: 'off',
            autoHeight: 'off',
            hideSliderAtLimit: 0,
            hideCaptionAtLimit: 0,
            hideAllCaptionAtLilmit: 0,
            debugMode: false,
            fallbacks: {
              simplifyAll: 'off',
              nextSlideOnWindowFocus: 'off',
              disableFocusListener: false
            }
          });
      }

      $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        /*disableOn: 700,*/
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 10,
        preloader: false,
        fixedContentPos: false
      });
      $('.popup-youtube, .popup-vimeo1, .popup-gmaps').magnificPopup({
        /*disableOn: 700,*/
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 10,
        preloader: false,
        fixedContentPos: false
      });
      jQuery('.owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        navText: ["<i class='fa fa-caret-left'></i>", "<i class='fa fa-caret-right'></i>"],
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 3
          },
          1000: {
            items: 5
          }
        }
      });
    })(jQuery);
  }
}
